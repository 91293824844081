<template>
    <div class="card">
        <HeaderPanel tooltip="Listagem de unidades" :title="'Unidades'" nomeTelaDoManual="customer-branches-gro">
            <template #extraContent>
                <div class="flex flex-row justify-content-center align-items-center gap-2 identificacao-customer">
                    <i class="pi pi-building text-4xl"></i>
                    <div class="flex flex-column">
                        <span class="font-semibold line-height-3 cursor-pointer nome-cliente" v-tooltip.bottom="customer?.name">{{
                            customer?.name
                        }}</span>
                        <span class="">Cód. SOC - {{ customer?.code }}</span>
                    </div>
                </div>
            </template>
        </HeaderPanel>
        <FiltrosUnidade
            v-model:filtrosExtras="filtrosDoComponenteUnidade"
            :customerId="customer?.id"
            :statusOptions="statusCustomerBranchOptions"
            @loadCustomerBranches="aplicarFiltros"
        />
        <DataTable
            dataKey="id"
            :value="records"
            lazy
            :loading="loading"
            loadingIcon="pi pi-spinner"
            paginator
            :rows="recordsPerPage"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="[10, 50, 100]"
            tableStyle="min-width: 50rem; height: 100%"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            :sortField="currentSortField"
            :sortOrder="currentSortOrder"
            removableSort
            @page="onPage"
            @sort="onSort"
        >
            <Column
                v-for="col of unidadesColumns"
                :field="col.field"
                :key="col.field"
                :style="col.style"
                :sortable="col.sortable"
                :sortField="col.sortField"
            >
                <template #header>
                    <div class="font-semibold">{{ col.header }}</div>
                </template>

                <template #empty> Nenhum registro encontrado.</template>
                <template #loading>
                    <AppLoadingWrapper />
                </template>

                <template #body="{ data, index }">
                    <template v-if="col.field === 'produtosContratados'">
                        <Button
                            v-tooltip="{ value: 'Nenhum produto contratado.', disabled: !!data?.produtoServicoUnidade?.length }"
                            label="Ver produtos"
                            class="p-button-link"
                            @click="openDialogProdutosContratados(data, !data?.produtoServicoUnidade?.length)"
                        />
                    </template>

                    <template v-else-if="col.field === 'statusGro'">
                        <StatusGrid :status="getStatusComTodasAsConfigs(data[col.field])" />
                    </template>

                    <template v-else-if="col.field === 'acoes'">
                        <Button class="p-button-text p-button-secondary" icon="pi pi-ellipsis-v" @click="toggleMenu($event, index)" />
                        <Menu :ref="(el) => (menu[index] = el)" :model="getActionItems(data)" :popup="true" />
                    </template>

                    <template v-else>
                        <span>{{ data[col.field] }}</span>
                    </template>
                </template>
            </Column>
        </DataTable>
        <DialogDetalhesProdutosContratados ref="dialogDetalhesProdutosContratados" />
        <DialogLiberarVisitaTecnica ref="dialogLiberarVisitaTecnica" @onSuccessLiberacao="load" @onErrorLiberacao="openDialogFalhaLiberacao" />
        <DialogCancelarVisitaTecnica ref="dialogCancelarVisitaTecnica" @onSuccessCancelamentoLiberacao="load" />
        <DialogFalhaLiberacao ref="dialogFalhaLiberacao" />
        <DialogMaisDetalhes ref="dialogMaisDetalhes" />
        <DialogRelatoriosLpp ref="dialogRelatoriosLpp" @onSuccessEnvioRelatorioPorEmail="openDialogSucessoEnvioRelatorioPorEmail" />
        <DialogSucessoEnvioRelatorioPorEmail ref="dialogSucessoEnvioRelatorioPorEmail" />
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getClientBase } from '../../services/http';
import { checkPermission } from '../../common/check-permission';
import TipoProdutoServicoEnum from '../../enums/TipoProdutoServicoEnum';
import BaseService from '../../services/BaseService';
import StatusGroCustomerBranchEnum from '../../enums/StatusGroCustomerBranch';
import HeaderPanel from './components/HeaderPanel';
import FiltrosUnidade from './components/FiltrosUnidade';
import StatusGrid from './components/StatusGrid';
import DialogDetalhesProdutosContratados from './components/DialogDetalhesProdutosContratados';
import DialogLiberarVisitaTecnica from './components/DialogLiberarVisitaTecnica';
import DialogCancelarVisitaTecnica from './components/DialogCancelarVisitaTecnica';
import DialogFalhaLiberacao from './components/DialogFalhaLiberacao';
import DialogMaisDetalhes from './components/DialogMaisDetalhes';
import DialogRelatoriosLpp from './components/DialogRelatoriosLpp';
import DialogSucessoEnvioRelatorioPorEmail from './components/DialogSucessoEnvioRelatorioPorEmail';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const router = useRouter();
const store = useStore();
const queryParams = ref({});
const menu = ref([]);
const records = ref([]);
const loading = ref(false);
const sort = ref(null);
const currentSortField = ref(null);
const currentSortOrder = ref(null);
const page = ref(1);
const recordsPerPage = ref(10);
const totalRecords = ref(0);
const totalPages = ref(0);
const customer = ref({});
const filtrosDoComponenteUnidade = ref({});
const statusCustomerBranchOptions = ref([]);
const produtoServicoOptions = ref([]);
const produtosServicosTodasUnidades = ref([]);
const unidadesColumns = [
    {
        field: 'name',
        header: 'Unidade',
        sortable: true,
        sortField: 'customer_branches.name'
    },
    {
        field: 'produtosContratados',
        header: 'Produtos contratados',
        sortable: false
    },
    {
        field: 'statusGro',
        header: 'Status',
        sortable: true,
        sortField: 'customer_branches.status_gro'
    },
    {
        field: 'acoes',
        sortable: false,
        style: 'width: 55px'
    }
];
const styleMapStatus = {
    [StatusGroCustomerBranchEnum.NOVA_UNIDADE]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroCustomerBranchEnum.EM_VISITA_TECNICA]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroCustomerBranchEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

const dialogDetalhesProdutosContratados = ref(null);
const dialogLiberarVisitaTecnica = ref(null);
const dialogFalhaLiberacao = ref(null);
const dialogSucessoEnvioRelatorioPorEmail = ref(null);
const dialogCancelarVisitaTecnica = ref(null);
const dialogMaisDetalhes = ref(false);
const dialogRelatoriosLpp = ref(false);

async function onPage(event) {
    page.value = event.page + 1;
    recordsPerPage.value = event.rows;
    await load();
}

async function onSort(event) {
    currentSortField.value = event.sortField;
    currentSortOrder.value = event.sortOrder;
    const field = event.sortField
        ?.split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
        ? `${event.sortField
              ?.split(/(?=[A-Z])/)
              .join('_')
              .toLowerCase()}`
        : '';
    const order = event.sortOrder == 1 ? `ASC` : 'DESC';
    if (field) {
        sort.value = {
            [field]: order
        };
    } else {
        sort.value = null;
    }

    await load();
}

async function toggleMenu(event, index) {
    menu.value?.[index].toggle(event);
}

function getActionItems(record) {
    return [
        {
            label: 'Liberar visita técnica',
            icon: 'pi pi-pencil',
            disabled: () =>
                !checkPermission('gestaosesmt_gro_unidade:liberar') ||
                !possuiProdutoLppContratado(record) ||
                record.statusGro === statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.EM_VISITA_TECNICA)?.value,
            command: () => openDialogLiberarVisitaTecnica(record)
        },
        {
            label: 'Mais detalhes',
            icon: 'pi pi-info-circle',
            disabled: () =>
                record.statusGro === statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.NOVA_UNIDADE)?.value,
            command: () => openDialogMaisDetalhes(record)
        },
        {
            label: 'Cancelar visita técnica',
            icon: 'pi pi-minus-circle',
            disabled: () =>
                !checkPermission('gestaosesmt_gro_unidade:cancelar') ||
                record.statusGro !== statusCustomerBranchOptions.value.find((p) => p.label === StatusGroCustomerBranchEnum.EM_VISITA_TECNICA)?.value,
            command: () => openDialogCancelarVisitaTecnica(record)
        },
        {
            label: 'Geração de relatórios',
            icon: 'pi pi-file',
            command: () => openDialogGeracaoRelatorios(record)
        }
    ];
}

function openDialogProdutosContratados(data, semProduto) {
    if (semProduto) return;
    dialogDetalhesProdutosContratados.value.openDialog(data);
}

function openDialogLiberarVisitaTecnica(record) {
    dialogLiberarVisitaTecnica.value?.openDialog(record);
}

function openDialogFalhaLiberacao(errorsList) {
    dialogFalhaLiberacao.value.openDialog(errorsList);
}

function openDialogMaisDetalhes(record) {
    dialogMaisDetalhes.value.openDialog(record);
}

function openDialogCancelarVisitaTecnica(record) {
    dialogCancelarVisitaTecnica.value?.openDialog(record);
}

function openDialogGeracaoRelatorios(record) {
    dialogRelatoriosLpp.value.openDialog(record, possuiProdutoLppContratado(record));
}

function openDialogSucessoEnvioRelatorioPorEmail() {
    dialogSucessoEnvioRelatorioPorEmail.value.openDialog();
}

function possuiProdutoLppContratado(record) {
    const produtoLpp = produtoServicoOptions.value.find((p) => p.label === TipoProdutoServicoEnum.LPP)?.value;
    const contemLpp = record.produtoServicoUnidade.some((p) => p.produtoServico.tipoInventario === produtoLpp);
    return contemLpp;
}

async function getCustomer(customerId) {
    const { data } = await new BaseService('/customers').findById(customerId);
    customer.value = data;
}

function getStatusCustomerBranchOptions() {
    statusCustomerBranchOptions.value = Object.keys(StatusGroCustomerBranchEnum).map(function (type) {
        return {
            label: `${StatusGroCustomerBranchEnum[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroCustomerBranchEnum[type]]
        };
    });
}

function getProdutoServicoOptions() {
    produtoServicoOptions.value = Object.keys(TipoProdutoServicoEnum).map(function (type) {
        return {
            label: `${TipoProdutoServicoEnum[type]}`,
            value: `${type}`
        };
    });
}

function getStatusComTodasAsConfigs(status) {
    return statusCustomerBranchOptions.value.find((p) => p.value === status) ?? { label: 'NÃO INFORMADO OU INVÁLIDO' };
}

async function aplicarFiltros(removerFiltros = false) {
    if (removerFiltros) {
        filtrosDoComponenteUnidade.value = {};
        atualizarQueryParamsStore({});
    }

    await load();
}

async function atualizarQueryParams() {
    const { customerBranch, status } = filtrosDoComponenteUnidade.value;

    atualizarQueryParamsStore({
        customerBranch: customerBranch ? JSON.stringify(customerBranch.id) : undefined,
        status
    });

    await router.replace({
        query: {
            customerBranch: customerBranch ? JSON.stringify(customerBranch.id) : undefined,
            status
        }
    });
}

function atualizarQueryParamsStore(params) {
    store.dispatch('setQueryParamsTelaUnidadesGro', params);
}

async function load() {
    try {
        loading.value = true;
        const { data } = await getClientBase().get('/customer/branches', {
            params: {
                limit: recordsPerPage.value,
                page: page.value,
                sort: sort.value,
                filtrosExtras: {
                    ativo: true,
                    customerId: router.currentRoute.value?.params?.id,
                    customerBranchId: filtrosDoComponenteUnidade.value?.customerBranch?.id,
                    status: filtrosDoComponenteUnidade.value?.status
                }
            }
        });

        records.value = data.items;
        totalRecords.value = data.meta?.totalItems;
        totalPages.value = data.meta?.totalPages;

        await getProdutosServicosDasUnidades(records.value);
        setProdutosServicosNasUnidades(records.value, produtosServicosTodasUnidades);

        loading.value = false;
    } catch (error) {
        loading.value = false;
        const message = error?.response?.data?.message;
        this.$toast.add({
            severity: 'error',
            summary: 'Erro ao listar! ' + message,
            life: 3000
        });
    }
}

async function getProdutosServicosDasUnidades(records) {
    if (!records.length) return;

    const promises = records.map((p) =>
        getClientBase()
            .get(`/produtos-servicos-unidades/cliente/${p.cliente.id}/unidade/${p.id}`)
            .then((response) => ({ id: p.id, produtosServicosUnidade: response.data }))
            .catch((error) => {
                throw error;
            })
    );
    const results = await Promise.all(promises);
    produtosServicosTodasUnidades.value = results;
}

function setProdutosServicosNasUnidades(records, produtosServicosTodasUnidades) {
    if (!records.length) return;

    records.forEach((record) => {
        const produtosServicosUnidadeEncontrados = produtosServicosTodasUnidades.value.find((item) => item.id === record.id);
        produtosServicosUnidadeEncontrados
            ? (record.produtoServicoUnidade = produtosServicosUnidadeEncontrados.produtosServicosUnidade)
            : (record.produtoServicoUnidade = []);
    });
}

onBeforeMount(async () => {
    const routeQueryParams = router.currentRoute.value?.query;
    queryParams.value = Object.keys(routeQueryParams).length ? routeQueryParams : store.getters.queryParamsTelaUnidadesGro;
    if (Object.keys(queryParams.value).length) {
        let customerBranch;

        if (queryParams.value?.customerBranch) {
            const { data } = await getClientBase().get(`/customer/branches/${queryParams.value?.customerBranch}`);
            customerBranch = data;
        }

        filtrosDoComponenteUnidade.value = {
            customerBranch: customerBranch,
            status: queryParams.value?.status
        };
        await atualizarQueryParams();
    } else {
        filtrosDoComponenteUnidade.value = {
            customerBranch: queryParams.value?.customerBranch,
            status: queryParams.value?.status
        };

        await atualizarQueryParams();
    }

    try {
        const customerId = router.currentRoute.value?.params?.id ? Number(router.currentRoute.value?.params?.id) : 0;
        await getCustomer(customerId);
        getStatusCustomerBranchOptions();
        getProdutoServicoOptions();
        await atualizarQueryParams();
        await load();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Ocorreu um problema',
            detail: error,
            life: 5000
        });
    }
});
</script>

<style lang="scss" scoped>
.identificacao-customer {
    padding: 1rem;
    border: solid #ededed 1px;
    border-radius: 4px;
}

.nome-cliente {
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
