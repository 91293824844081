<template>
    <div>
        <div class="flex flex-column gap-2 p-1">
            <div class="flex justify-content-between">
                <span><h4><b>{{ props.produtoServico.nome }}</b></h4></span>
                <span><b>Avaliador(a): {{ nomeAvaliador ? nomeAvaliador : 'Sem responsável'}}</b></span>
            </div>
            <div v-if="props.status && statusCustomerOptions.length">
                <StatusGrid :status="getStatusComTodasAsConfigs(props.status)" />
            </div>
        </div>
         <div class="w-full mt-4" style="height: 1px; background: #EDEDED" />
    </div>
</template>

<script setup>
import { defineProps, onBeforeMount, ref } from 'vue';
import StatusGroLpp from '../../../enums/StatusGroLpp';
import StatusGrid from './StatusGrid.vue';

const statusCustomerOptions = ref([]);
const styleMapStatus = {
    [StatusGroLpp.SEM_DADOS]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroLpp.EM_ANDAMENTO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroLpp.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

function getStatusCustomerOptions() {
    statusCustomerOptions.value = Object.keys(StatusGroLpp)
        .map(function (type) {
            return {
                label: `${StatusGroLpp[type]}`,
                value: `${type}`,
                style: styleMapStatus[StatusGroLpp[type]]
            };
        });
}

function getStatusComTodasAsConfigs(status) {
    return statusCustomerOptions.value.find((p) => p.value === status) ?? { label: 'NÃO INFORMADO OU INVÁLIDO' };
}

const props = defineProps({
    produtoServico: {
        type: Object
    },
    nomeAvaliador: {
        type: String
    },
    status: {
        type: String
    },
    customerBranchId: {
        type: Number
    },
    customerId: {
        type: Number
    }
});

onBeforeMount(() => {
    getStatusCustomerOptions();
})

</script>
