<template>
    <Dialog
        v-model:visible="isOpen"
        :style="{ width: '450px' }"
        :header="`Tem certeza que deseja cancelar a visita técnica da unidade ${customerBranchAtual?.name}?`"
        :modal="true"
        :draggable="false"
        @hide="closeDialog"
    >
        <div class="field-checkbox mt-3 mb-5">
            <InputSwitch id="enviarPorEmail" v-model="form.enviarPorEmail" />
            <label for="enviarPorEmail">Enviar notificação para o avaliador</label>
        </div>
        <div class="field p-fluid">
            <label for="emails">E-mail</label>
            <Chips
                id="emails"
                :disabled="!form?.enviarPorEmail"
                v-model="v$.emails.$model"
                placeholder="Digite um e-mail"
                separator=","
                :class="{ 'p-invalid': v$.emails.$errors.length && v$.emails.lazy.$dirty }"
                aria-describedby="emails-help"
            />
            <small v-if="!v$.emails.$errors.length" id="emails-help">Para adicionar mais de um e-mail utilize a vírgula.</small>
            <small v-if="v$.emails.$errors.length && v$.emails.lazy.$dirty" class="p-error">
                {{ v$.emails.$errors[0].$message }}
            </small>
        </div>
        <template #footer>
            <Button label="Não" class="p-button-text mr-4" :disabled="loadingCancelarVisitaTecnica" @click="closeDialog" />
            <Button label="Sim" :disabled="isDisable" :loading="loadingCancelarVisitaTecnica" @click="cancelarVisitaTecnica" />
        </template>
    </Dialog>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import { computed, defineEmits, defineExpose, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { showError, showSuccess } from '../../../utils/Toast';
import { getClientBase } from '../../../services/http';

const emit = defineEmits(['onSuccessCancelamentoLiberacao']);
const toast = useToast();
const isOpen = ref(false);
const form = ref({});
const submitted = ref(false);
const customerBranchAtual = ref({});
const loadingCancelarVisitaTecnica = ref(false);
const inventarioHierarquia = ref({});

const isDisable = computed(() => {
    return (form.value?.enviarPorEmail && !form.value?.emails?.length) || (v$.value.emails.$errors.length && v$.value.emails.lazy.$dirty);
});

const rules = {
    emails: {
        required: helpers.withMessage(
            'Ao menos um e-mail deve ser informado',
            requiredIf(() => form.value?.enviarPorEmail)
        ),
        format: helpers.withMessage('Pelo menos um dos e-mails informados não é válido.', (value) => {
            if (!form.value?.enviarPorEmail || !value || !value.length) {
                return true;
            }
            const emailsAlterados = value.map((p) => p.trim());
            return emailsAlterados.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
        }),
        lazy: true
    }
};

const v$ = useVuelidate(rules, form);

watch(
    () => form.value.emails,
    async () => {
        await validarCampoEmail();
    }
);

async function openDialog(customerBranch) {
    isOpen.value = true;
    customerBranchAtual.value = customerBranch;
    await getInventarioHierarquia();
}

async function getInventarioHierarquia() {
    const { data } = await getClientBase().get(
        `inventario-hierarquia/find-aplicacao-levantamento-by/cliente/${customerBranchAtual.value?.customerId}/unidade/${customerBranchAtual.value?.id}`
    );
    inventarioHierarquia.value = data;
}

async function validarCampoEmail() {
    v$.value.emails.$touch();
}

async function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
}

async function cancelarVisitaTecnica() {
    try {
        const isValid = await v$.value.$validate();

        if (!isValid) return;

        submitted.value = true;
        loadingCancelarVisitaTecnica.value = true;
        const emailsEnvioCancelamento = form.value.enviarPorEmail ? form.value?.emails?.map((p) => p?.trim()) : [];

        await getClientBase().post(`liberacao-hierarquia-checklist/cancelar-visita-tecnica`, {
            clienteId: customerBranchAtual.value?.customerId,
            unidadeId: customerBranchAtual.value?.id,
            emailsEnvioCancelamento
        });
        await sleep(2000);
        closeDialog();
        showSuccess(toast, 'Visita técnica cancelada com sucesso.');
        emit('onSuccessCancelamentoLiberacao');
    } catch (error) {
        closeDialog();
        showError(toast, {}, 'Falha no cancelamento. Tente novamente.');
    }
}

function closeDialog() {
    isOpen.value = false;
    v$.value.$reset();
    form.value = {};
    submitted.value = false;
    loadingCancelarVisitaTecnica.value = false;
}

defineExpose({
    openDialog
});
</script>
