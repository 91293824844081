<template>
    <AppInfinityDropdown
        ref="infintyDropdown"
        v-model="value"
        optionLabel="nome"
        :filterFields="['nome']"
        :service="$service"
        :recordsPerPage="recordsPerPage"
        :filtrosExtras="filtrosExtras"
    />
</template>
<script>
import BaseService from '@/services/BaseService'

export default {
    name: 'InfinityDropdownProfissionalSesmt',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object
        },
        recordsPerPage: {
            type: Number,
            default: 20
        },
        filtrosExtras: {
            type: Object,
            default: JSON.parse('{"responsavel": true}')
        }
    },    
    data() {
        return {
            $service: null
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.$service = new BaseService('/profissionais-sesmt');
    }
};
</script>
