<template>
    <div>
        <Dialog v-model:visible="isOpen" :style="{ width: '20%' }" header="Detalhes" @hide="close" :modal="true">
            <div class="flex flex-column gap-2">
                <span><b>Liberado em:</b> {{ dataLiberacao }} </span>
                <span><b>Avaliador:</b> {{ avaliador }} </span>
            </div>
            <div class="mt-2">
                <span><b>Enviado para:</b> {{ emailEnvio }} </span>
            </div>
            <template #footer>
                <Button label="Fechar" class="p-button-primary justify-content-end" @click="close" />
            </template>
        </Dialog>
    </div>
</template>

<script setup>
import moment from 'moment';
import { ref, defineExpose } from 'vue';
import OrigemAplicacaoInventarioHierarquiaEnum from '../../../enums/OrigemAplicacaoInventarioHierarquiasEnum';
import { getClientBase } from '../../../services/http';

const isOpen = ref(false);
const dataLiberacao = ref(null);
const avaliador = ref(null);
const emailEnvio = ref(null);
const origem = ref(null);

async function load(unidadeId, customerId) {
    const { data } = await getClientBase().get(`inventario-hierarquia/find-aplicacao-levantamento-by/cliente/${customerId}/unidade/${unidadeId}`);
    origem.value = data?.origemAplicacao == OrigemAplicacaoInventarioHierarquiaEnum.CHECKLIST ? 'APP' : 'WEB';
    dataLiberacao.value = moment(data?.aplicacaoLevantamento?.createdAt).format('DD/MM/YYYY') || 'Data não preenchida';
    emailEnvio.value = data?.aplicacaoLevantamento?.emailsEnvioLiberacao?.join(', ') || 'Nenhum email enviado';
    avaliador.value = data?.inventarioRisco?.inventarioRiscoResponsavel?.map((item) => item?.profissionalSesmt?.nome)?.toString() || 'Sem avaliador informado';
}

function close() {
    isOpen.value = false;
}

async function openDialog(data) {
    const { id, customerId } = data;
    isOpen.value = true;
    await load(id, customerId);
}

defineExpose({
    openDialog
});

</script>
