<template>
    <Dialog
        v-model:visible="isOpen"
        :style="{ width: '450px' }"
        :header="`Liberar visita técnica para ${customerBranchAtual?.name}`"
        :modal="true"
        :draggable="false"
        @hide="closeDialog"
    >
        <Message class="mb-5" severity="info" :closable="false">Os checkists serão liberados no aplicativo.</Message>
        <div class="field mb-5">
            <label for="avaliador">Avaliador técnico</label>
            <InfinityDropdownProfissionalSesmt
                id="avaliador"
                class="w-full"
                v-model="v$.avaliador.$model"
                :filtrosExtras="null"
                :class="{ 'p-invalid': v$.avaliador.$errors.length && v$.avaliador.lazy.$dirty }"
                @change="validarCampoAvaliador"
                showClear
            />
            <small v-if="v$.avaliador.$errors.length && v$.avaliador.lazy.$dirty" class="p-error">
                {{ v$.avaliador.$errors[0].$message }}
            </small>
        </div>
        <div class="field-checkbox mb-5">
            <InputSwitch id="enviarPorEmail" v-model="form.enviarPorEmail" />
            <label for="enviarPorEmail">Enviar notificação para o avaliador</label>
        </div>
        <div class="field p-fluid">
            <label for="emails">E-mail</label>
            <Chips
                id="emails"
                :disabled="!form?.enviarPorEmail"
                v-model="v$.emails.$model"
                placeholder="Digite um e-mail"
                separator=","
                :class="{ 'p-invalid': v$.emails.$errors.length && v$.emails.lazy.$dirty }"
                aria-describedby="emails-help"
            />
            <small v-if="!v$.emails.$errors.length" id="emails-help">Para adicionar mais de um e-mail utilize a vírgula.</small>
            <small v-if="v$.emails.$errors.length && v$.emails.lazy.$dirty" class="p-error">
                {{ v$.emails.$errors[0].$message }}
            </small>
        </div>
        <template #footer>
            <Button label="Cancelar" class="p-button-outlined mr-4" :disabled="loadingLiberar" @click="closeDialog" />
            <Button label="Liberar" :disabled="isDisable" :loading="loadingLiberar" @click="liberar" />
        </template>
    </Dialog>
</template>

<script setup>
import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { computed, defineEmits, defineExpose, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import BaseService from '../../../services/BaseService';
import { showError, showSuccess } from '../../../utils/Toast';
import InfinityDropdownProfissionalSesmt from '../../profissionais-sesmt/components/InfinityDropdownProfissionalSesmt';

const emit = defineEmits(['onSuccessLiberacao', 'onErrorLiberacao']);
const service = new BaseService('liberacao-hierarquia-checklist');
const toast = useToast();
const isOpen = ref(false);
const form = ref({});
const submitted = ref(false);
const customerBranchAtual = ref({});
const filtrosExtras = ref({ where: [] });
const hierarquiaIds = ref([]);
const loadingLiberar = ref(false);

const isDisable = computed(() => {
    return (
        !form.value?.avaliador ||
        !form.value?.avaliador.usuarioVinculadoId ||
        (form.value?.enviarPorEmail && !form.value?.emails?.length) ||
        (v$.value.emails.$errors.length && v$.value.emails.lazy.$dirty)
    );
});

const rules = {
    avaliador: {
        required: helpers.withMessage('O avaliador é obrigatório.', required),
        validacaoPersonalizadaAvaliador: helpers.withMessage(
            'O avaliador selecionado não possui usuário vinculado.',
            (value) => !!value?.usuarioVinculadoId
        ),
        lazy: true
    },
    emails: {
        required: helpers.withMessage(
            'Ao menos um e-mail deve ser informado',
            requiredIf(() => form.value?.enviarPorEmail)
        ),
        format: helpers.withMessage('Pelo menos um dos e-mails informados não é válido.', (value) => {
            if (!form.value?.enviarPorEmail || !value || !value.length) {
                return true;
            }
            const emailsAlterados = value.map((p) => p.trim());
            return emailsAlterados.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
        }),
        lazy: true
    }
};

const v$ = useVuelidate(rules, form);

watch(
    () => form.value.emails,
    async () => {
        await validarCampoEmail();
    }
);

async function openDialog(customerBranch) {
    isOpen.value = true;
    customerBranchAtual.value = customerBranch;
    montarFiltroCliente(customerBranch.cliente.id);
    montarFiltroUnidade(customerBranch.id);
    await carregarHierarquias();
}

function montarFiltroCliente(customerId) {
    if (customerId) {
        filtrosExtras.value.where.push({
            prop: 'cliente.id',
            operator: 'equal',
            values: [customerId]
        });
    }
}

function montarFiltroUnidade(customerBranchId) {
    if (customerBranchId) {
        filtrosExtras.value.where.push({
            prop: 'customerBranch.id',
            operator: 'equal',
            values: [customerBranchId]
        });
    }
}

async function carregarHierarquias() {
    try {
        const { data } = await service.findAll({ filtrosExtras: filtrosExtras.value });
        hierarquiaIds.value = data.map((p) => p.hierarquiaId);
    } catch (error) {
        showError(toast, error);
    }
}

async function validarCampoAvaliador() {
    v$.value.avaliador.$touch();
}

async function validarCampoEmail() {
    v$.value.emails.$touch();
}

async function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
}

async function liberar() {
    try {
        const isValid = await v$.value.$validate();

        if (!isValid) return;

        submitted.value = true;
        loadingLiberar.value = true;
        const emailsEnvioLiberacao = form.value.enviarPorEmail ? form.value?.emails?.map((p) => p?.trim()) : [];

        await service.save({
            clienteId: customerBranchAtual.value?.customerId,
            unidadeId: customerBranchAtual.value?.id,
            hierarquiaIds: hierarquiaIds.value,
            responsavelLevantamentoIds: [form.value?.avaliador.id],
            emailsEnvioLiberacao,
            novaLiberacao: true
        });
        await sleep(2000);
        showSuccess(toast, 'Liberação realizada com sucesso.');
        closeDialog();
        emit('onSuccessLiberacao');
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            emit('onErrorLiberacao', error?.response?.data?.details?.response?.retornoErrosCadastro?.listaDeErros);
            closeDialog();
            return;
        }
        
        submitted.value = false;
        loadingLiberar.value = false;
        showError(toast, error, 'Não foi possível liberar a visita técnica, por favor tente novamente.');
    }
}

function closeDialog() {
    isOpen.value = false;
    v$.value.$reset();
    form.value = {};
    filtrosExtras.value = { where: [] };
    submitted.value = false;
    loadingLiberar.value = false;
}

defineExpose({
    openDialog
});
</script>
